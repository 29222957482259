@import '@/scss/base/variables';
@import '../../lib/blocks/shop-info';

#content {
  @media only screen and (max-width: $breakpoint-s) {
    padding-top: 0;
  }
}

#item-header {
  .owner-actions {
    margin: 15px 0;

    a {
      color: $gray600;
      margin-right: 12px;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
      }

      > i {
        font-size: 18px;
        margin-right: 3px;
        position: relative;
        top: -1px;
        vertical-align: middle;
      }
    }
  }
}

#item {
  display: grid;
  gap: 0 60px;
  grid-template-areas:
    'image info'
    'image info2'
    'body info2'
    '. info2';
  grid-template-columns: 640px 280px;
  margin-bottom: 40px;

  @media only screen and (max-width: $breakpoint-s) {
    display: block;
    margin-bottom: 20px;
  }
}

.photo-viewer {
  grid-area: image;

  @media only screen and (max-width: $breakpoint-s) {
    margin: 0 -10px;
  }

  photo-viewer {
    display: block;
    width: 640px;

    @media only screen and (max-width: $breakpoint-s) {
      width: auto;
    }

    img {
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
      width: 100%;
    }
  }
}

.photo-viewer-image-wrap {
  aspect-ratio: 1 / 1;
  background: $gray100 url('@/images/ui/loader.svg') no-repeat center center;
  height: 640px;
  position: relative;
  width: 640px;

  @media only screen and (max-width: $breakpoint-s) {
    height: auto;
    width: 100vw;
  }

  img {
    cursor: pointer;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
    width: 100%;
  }

  .photo-viewer-comment {
    background-color: rgba($black, 0.4);
    bottom: 0;
    box-sizing: border-box;
    color: $white;
    overflow: hidden;
    padding: 5px 10px;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.photo-viewer-main {
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: $breakpoint-s) {
    height: auto;
    width: auto;
  }

  > ul {
    display: flex;
  }
}

.photo-viewer-thumbnail {
  box-sizing: content-box;
  display: flex;
  overflow: auto hidden;
  padding: 8px 8px 12px 0;

  li {
    .photo {
      box-sizing: content-box;
      height: 66px;
      margin: 0 1px 0 7px;
      width: 66px;

      @media only screen and (max-width: $breakpoint-s) {
        height: 60px;
        width: 60px;
      }

      img {
        border: 1px solid transparent;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        height: 100%;
        object-fit: cover;
        vertical-align: bottom;
        width: 100%;
      }
    }

    &.is-current .photo img {
      border-color: $black;
    }
  }
}

/* --------------------------------------------------------------------- */

#item-info {
  grid-area: info;

  h1 {
    font-size: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: $breakpoint-s) {
      font-size: 18px;
      margin-bottom: 10px;
      padding-top: 12px;
    }
  }

  > .cart-button {
    margin-bottom: 18px;
  }

  like-button {
    display: block;
    height: 29px;
    width: 100%;
  }

  .free-shipping {
    color: #ad0008;
    margin-bottom: 14px;
  }

  .item-option {
    margin: 18px 0 12px;

    @media only screen and (max-width: $breakpoint-s) {
      margin-top: 0;
    }

    > li {
      min-height: 17px;
      padding: 4px 0;

      > i {
        color: #4b4b4b;
        font-size: 24px;
        margin-right: 8px;
        position: relative;
        vertical-align: middle;
      }

      &.vintage > i {
        left: -3px;
        margin-right: 3px;
        top: -3px;
      }

      &.gift > i {
        top: -4px;
      }

      &.ordermade > i {
        top: -2px;
      }
    }
  }

  #item-price-like {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  send-request-button {
    display: block;
    height: 27.5px;
    margin: -10px 0 20px;
  }

  #item-price {
    .price {
      font-size: 20px;
      font-weight: bold;

      @media only screen and (max-width: $breakpoint-s) {
        font-size: 18px;
      }
    }

    .stock {
      font-size: 16px;
      margin-top: 5px;

      @media only screen and (max-width: $breakpoint-s) {
        font-size: 14px;
      }

      span {
        font-weight: bold;
      }
    }

    &.sold {
      .price {
        color: $text-color-disabled;
      }

      .stock {
        color: $text-color-disabled;
        font-weight: bold;
      }
    }
  }

  .coupon-promotion {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    > i {
      font-size: 16px;
      margin-top: 4px;
    }

    .coupon-text {
      color: inherit;
      font-weight: bold;
      text-decoration: underline;

      .due-date {
        font-weight: normal;
      }
    }
  }

  #share-message {
    display: flex;
    padding: 2px 0 30px;

    @media only screen and (max-width: $breakpoint-s) {
      padding-bottom: 20px;
    }

    .share-wrap {
      height: 51px;
      width: 33px;
    }
  }

  .item-vintage {
    border: 1px solid $gray200;
    border-radius: 6px;
    margin-bottom: 16px;
    padding: 12px 16px;
  }
}

#item-description {
  grid-area: body;
  padding-bottom: 15px;

  @media only screen and (max-width: $breakpoint-s) {
    padding-bottom: 30px;
  }

  .text {
    line-height: 191%;
    overflow: hidden;
    padding: 30px 0 0;

    @media only screen and (max-width: $breakpoint-s) {
      line-height: 1.6;
      padding: 6px 0 0;
    }

    a {
      text-decoration: underline;
    }
  }

  #item-tag {
    margin-top: 20px;

    > ul {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 20px;

      > li {
        margin: 0 8px 8px 0;

        > a {
          border: 1px solid #ccc;
          display: block;
          padding: 4px 8px;

          &:hover {
            background-color: $gray150;
            text-decoration: none;
          }
        }
      }
    }
  }
}

#item-info2 {
  grid-area: info2;
}

#shipping {
  summary {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0;
    position: relative;

    &::after {
      color: #9a9585;
      content: $icon-arrow-bottom;
      font-family: $font-icon;
      font-size: 28px;
      line-height: 1;
      position: absolute;
      right: 5px;
      top: 2px;
    }

    &.active::after {
      transform: rotate(180deg);
    }
  }

  details[open] summary::after {
    transform: rotate(180deg);
  }

  .no-shipping-method {
    margin: 0 0 30px;

    h2 {
      font-size: 16px;
      font-weight: bold;
      padding: 5px 0;
    }

    p {
      border: 1px solid $gray200;
      border-radius: 6px;
      margin-top: 4px;
      padding: 12px 16px;
    }
  }

  table {
    background: #fff;
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    font-size: 11px;
    padding: 4px 2px;
    text-align: center;
  }

  th {
    &:last-child {
      width: 62px;
    }

    .bulk-buying {
      align-items: center;
      display: flex;

      button {
        color: #d9d9d9;
        margin: 2px 0 0;
        padding: 1px 3px;
      }
    }
  }

  td:first-child {
    font-weight: bold;
    text-align: left;
  }

  td:last-child {
    padding-right: 18px;
  }

  .note {
    margin-top: 5px;
  }

  .feature {
    text-align: center;
  }

  .free-shipping {
    color: #ad0008;
    margin-top: 8px;
  }

  #shipping-period {
    margin-top: 30px;

    @media only screen and (max-width: $breakpoint-s) {
      margin-top: 20px;
    }

    .heading {
      align-items: center;
      display: flex;
      font-size: 16px;
      font-weight: bold;
    }

    .days {
      font-size: 16px;
      font-weight: normal;
      margin-left: 20px;
    }
  }
}

#item-notice {
  margin-top: 30px;

  @media only screen and (max-width: $breakpoint-s) {
    margin-top: 20px;
  }

  summary {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0;
    position: relative;

    &::after {
      color: #9a9585;
      content: $icon-arrow-bottom;
      font-family: $font-icon;
      font-size: 28px;
      line-height: 1;
      position: absolute;
      right: 5px;
      top: 2px;
    }

    &.active::after {
      transform: rotate(180deg);
    }
  }

  &[open] summary::after {
    transform: rotate(180deg);
  }

  .text {
    line-height: 160%;
    margin-top: 5px;
  }
}

.maker-info {
  margin: 60px 0 20px;

  h2 {
    font-size: 16px;
    margin: 0 0 10px;
  }

  .maker-area {
    display: flex;
  }

  .maker-photo img {
    border: 1px solid #cfcec5;
    object-fit: cover;

    @media only screen and (max-width: $breakpoint-s) {
      height: auto;
      width: 90px;
    }
  }

  .maker-content {
    flex: 1 1 auto;
    margin-left: 14px;
    position: relative;
  }

  .maker-name {
    font-weight: bold;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .maker-profile {
    margin-top: 10px;

    > p {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .maker-link {
      margin-top: 4px;
      text-align: right;

      a {
        padding-left: 12px;
        position: relative;

        &::before {
          content: $icon-arrow-right;
          font-family: $font-icon;
          font-size: 18px;
          left: -5px;
          line-height: 1;
          position: absolute;
          top: -2px;
        }
      }
    }
  }

  .maker-photo-item {
    margin-top: 20px;

    .items {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(3, 1fr);

      li img {
        vertical-align: bottom;

        @media only screen and (max-width: $breakpoint-s) {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

.shop-info {
  margin: 60px 0 20px;
}

.maker-info + .shop-info {
  margin-top: 30px;
}

.information {
  border: 1px solid $gray200;
  border-radius: 4px;
  margin: 20px 0 15px;
  padding: 14px 15px 12px;

  .information-title {
    align-items: center;
    display: flex;
    margin: 0 0 8px;
  }

  .information-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    margin-bottom: 6px;
    overflow: hidden;
  }

  .information-more {
    text-align: right;

    a {
      padding-left: 12px;
      position: relative;

      &::before {
        content: $icon-arrow-right;
        font-family: $font-icon;
        font-size: 18px;
        left: -5px;
        line-height: 1;
        position: absolute;
        top: -2px;
      }
    }
  }
}

#feedback {
  margin: 30px 0;

  .heading {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    .link a {
      text-decoration: underline;
    }
  }

  ul > li {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 8px 0;
  }

  .photo img {
    border: 1px solid #f1ece7;
  }

  .exp {
    flex: 1;
    font-size: 12px;
    overflow: hidden;

    .item-name {
      display: block;
      font-weight: bold;
      overflow: hidden;
      padding-bottom: 3px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .star {
      margin-bottom: 2px;

      img {
        position: relative;
        top: 2px;
      }
    }
  }
}
